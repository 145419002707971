import * as React from 'react';
import clsx from 'clsx';
import {
  StyledComponentProps,
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { StyleRules } from '@material-ui/core/styles';
import { drawerWidth } from '../Dashboard/styles';
export type IProps = StyledComponentProps & HeaderProps;
export interface HeaderProps {
  leftLinks?: React.ReactNode;
  rightLinks?: React.ReactNode;
  brandComponent: React.ReactNode;
  isLoggedIn: boolean;
  open: boolean;
  shouldHideAside: boolean;
  handleDrawerOpen: (event: React.FormEvent) => void;
}

const headerStyle = (theme): StyleRules => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  root: {
    boxShadow: 'none',
    height: 64,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  menuButtonHidden: {
    display: 'none',
  },
  navLink: {
    color: '#888',
    textTransform: 'capitalize',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flex: '1 1 auto',
    height: '100%',
  },
});

const Header: React.SFC<IProps> = function({
  classes,
  brandComponent,
  isLoggedIn,
  open,
  handleDrawerOpen,
  leftLinks,
  rightLinks,
  shouldHideAside = false,
}) {
  return (
    <AppBar
      classes={{ root: classes.root }}
      position="fixed"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar
        className={classes.toolbar}
        style={{ height: '100%' }}
        variant="dense"
      >
        {!shouldHideAside && (
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              (open || !isLoggedIn) && classes.menuButtonHidden,
            )}
          >
            <ArrowForward />
          </IconButton>
        )}
        {!open && brandComponent}
        <div className={classes.flex}>{leftLinks}</div>

        {rightLinks}
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(headerStyle)(Header);
